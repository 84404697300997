import get from 'lodash-es/get'
import api from '/~/core/api'
import { BaseProcessor } from '/~/core/processors/base'

export class EntityProcessor extends BaseProcessor {
  constructor(params) {
    super(params)

    this.entity = this.params.entity
    this.mapping = this.params.mapping
    this.pagination = this.params.pagination || null
    this.perPage = this.params.perPage || 25
    this.query = this.params.query || {}
    this.queryString = this.params.queryString || null
    this.reset()
  }

  get total() {
    return Number(get(this.pagination, 'totalItems', 0))
  }

  get totalPages() {
    return get(this.pagination, 'totalPages')
  }

  get hasNextPage() {
    return get(this.pagination, 'hasNextPage')
  }

  reset(options = {}) {
    const { force = true, silent = false } = options

    if (!silent) {
      this.processing = true
    }

    if (force) {
      this.destroyHits()
    }

    this.page = 1
    this.message = ''

    if (!silent) {
      this.processing = false
    }
  }

  async getTotal() {
    try {
      const response = await api.get(`/${this.entity}`, {
        params: {
          page: 1,
          perPage: 1,
        },
      })

      if (!this.pagination) {
        this.pagination = get(response, 'data.pagination', {})
      }
    } catch (err) {
      console.warn(`error while receives counts for ${this.entity}`)
    }
  }

  /*
   * Reset state and get first page for the current feed
   */
  async getData(params = {}) {
    const { query, force } = params

    this.reset({ force, silent: true })

    const notify = params.notify ?? true
    const perPage = params.perPage || this.perPage

    const fetch = async ({ page, perPage = this.perPage }) => {
      this.processing = true

      const options = {
        page,
        perPage,
        ...this.query,
        ...(query || {}),
      }
      const queryParams = [
        ...Object.keys(options)
          .filter((k) => options[k] !== undefined)
          .map(
            (k) => encodeURIComponent(k) + '=' + encodeURIComponent(options[k])
          ),
        this.queryString,
      ]
        .filter((i) => Boolean(i))
        .join('&')

      const response = await api.get(
        `/${[this.entity, queryParams].join('?')}`,
        {
          params: options,
          notify,
        }
      )

      if (this.fetch !== fetch) {
        return
      }

      const items = get(response, 'data.items', [])

      if (page === 1) {
        this.pagination = get(response, 'data.pagination', {})
      }

      if (this.mapping instanceof Function) {
        return items.map((item) => this.mapping(item))
      }

      return items
    }

    this.fetch = fetch

    try {
      const hits = await fetch({ page: 1, perPage })

      if (hits) {
        this.destroyHits()
        this.hits = hits

        this.processing = false
        this.loaded = true
      }

      return this.hits
    } catch (error) {
      console.error(error)
      this.message = get(error, 'response.data.message', 'Request Error')

      this.processing = false
      this.loaded = true
    }
  }

  refresh() {
    if (this.processing) {
      return
    }

    if (this.length === 0) {
      return this.getData()
    }

    const oldPage = this.page

    return this.getData({
      perPage: this.page * this.perPage,
      force: false,
    }).then((response) => {
      this.page = oldPage

      return response
    })
  }

  /*
   * Get next page for the current feed
   */
  async next() {
    if (this.processing) {
      return
    }

    this.processing = true

    const oldPage = this.page
    const nextPage = oldPage + 1

    this.page = nextPage

    try {
      const nextData = await this.fetch({ page: nextPage })

      if (nextData) {
        this.hits = this.hits.concat(nextData)

        this.processing = false
        this.loaded = true
      }

      return nextData || this.hits
    } catch (error) {
      this.page = oldPage
      console.error(error)

      this.processing = false
      this.loaded = true
    }
  }

  destroyHits() {
    const firstHit = get(this.hits, '0', {})

    if (firstHit.destroy instanceof Function) {
      this.hits.forEach((hit) => hit.destroy())
    }

    this.hits = []
  }

  removeHit(hit) {
    const index = this.hits.indexOf(hit)

    if (index >= 0) {
      if (hit.destroy instanceof Function) {
        hit.destroy()
      }

      this.hits.splice(index, 1)
      this.pagination.totalItems -= 1
    } else {
      console.error('There is no such hit to remove: ', hit)
    }
  }

  addHit(hit) {
    const { sortedHits, hits } = this

    if (this.perPage > this.length) {
      hits.push(hit)

      if (this.pagination) {
        this.pagination.totalItems += 1
      }
    } else {
      const hitToRemove = sortedHits[sortedHits.length - 1]
      const pureIndex = hits.indexOf(hitToRemove)

      if (pureIndex >= 0) {
        hits.splice(pureIndex, 1, hit)

        if (hitToRemove.destroy instanceof Function) {
          hitToRemove.destroy()
        }
      } else {
        console.warn('Element index is not finded')
      }
    }
  }
}
