import { UserRawData } from '/~/types/api'
import { UserVerificationsOtp } from '/~/types/api/user'
import { formatDollar } from '/~/utils/format/money'
import { ThirdPartyUserStorage } from '/~/composables/third-party-users/core/ThirdPartyUserStorage'

const sexLabels = {
  1: 'Male',
  2: 'Female',
  default: 'Undisclosed',
}

export class EonxUser {
  raw: UserRawData | null

  constructor(raw: UserRawData | null) {
    eonx.user = raw
    this.raw = raw
  }

  get authorized() {
    return Boolean(this.raw)
  }

  get externalId() {
    return this.raw?.externalId
  }

  get membershipId() {
    return this.raw?.membership?.id
  }

  get membershipName() {
    return this.raw?.membership?.name || ''
  }

  get membershipLabel() {
    return this.raw?.membership?.label || ''
  }

  get avatarUrl() {
    return this.raw?.avatar.url
  }

  get username() {
    return this.raw?.username
  }

  get firstName() {
    return this.raw?.firstName ?? ''
  }

  get lastName() {
    return this.raw?.lastName ?? ''
  }

  get fullName() {
    return this.raw?.fullName ?? ''
  }

  get initials() {
    return this.raw?.initials
  }

  get email() {
    return this.raw?.email
  }

  get mobile() {
    return this.raw?.mobile
  }

  get bio() {
    return this.raw?.bio
  }

  get joinDate() {
    return this.raw?.joinedDate
  }

  get birthDate() {
    return this.raw?.dob
  }

  get startDate() {
    return this.raw?.startedDate
  }

  get sex() {
    return this.raw?.sex
  }

  get sexText() {
    return sexLabels[this.sex ?? 'default'] ?? sexLabels.default
  }

  get amount() {
    return this.raw?.amount ?? {}
  }

  hasRole(roleSlug: string): boolean {
    return this.raw?.roles?.some((role) => role.slug === roleSlug) ?? false
  }

  get userBusiness() {
    return this.raw?.userBusiness
  }

  get isThirdPartyUser() {
    const thirdPartyUser = new ThirdPartyUserStorage().restore()

    return this.userBusiness && thirdPartyUser.hasRole('third-party-user')
  }

  get isBusinessOwner() {
    return this.userBusiness && !this.isThirdPartyUser
  }

  get totalSaving() {
    return formatDollar(this.raw?.amount.saving ?? '0')
  }

  get isUsingCombinedFees() {
    return ['executive', 'go rewards'].includes(
      this.membershipName.toLowerCase()
    )
  }

  get hasTemporaryEmail() {
    return /.*member-has-no-email.*/.test(this.email ?? '')
  }

  get rolesList() {
    return (this.raw?.roles ?? []).map((role) => role.slug)
  }

  get verifications() {
    return this.raw?.verifications
  }

  get otpVerifications() {
    return (
      this.verifications?.otp ??
      (this.verifications as UserVerificationsOtp | undefined)
    )
  }

  get hasUnfinishedOtpVerifications() {
    const otpVerifications = Object.values(this.otpVerifications ?? {})

    return otpVerifications.some(
      (verification) => verification.status !== 'verified'
    )
  }

  get hasUnfinishedIdentityVerifications() {
    return (
      this.verifications?.identity !== null &&
      this.verifications?.identity !== undefined &&
      this.verifications?.identity?.status !== 'verified'
    )
  }

  get hasUnfinishedVerifications() {
    if (!this.verifications) return false

    return (
      this.hasUnfinishedIdentityVerifications ||
      this.hasUnfinishedOtpVerifications
    )
  }
}
