import {
  PaymentMethodBankAccount,
  PaymentMethodCreditCard,
  PaymentMethodEwallet,
  PaymentMethodPoints,
} from '/~/types/api/payment-methods'

export enum FlowType {
  payment = 'PaymentOrder',
  purchase = 'Order',
  quickBuy = 'QuickBuyOrder',
  statement = 'StatementOrder',
  batch = 'BatchOrder',
  autopay = 'AutoPay',

  pointsProgramOrder = 'PointsProgramOrder',
  qrPayOrder = 'QRPayOrder',
  topUpOrder = 'TopUpOrder',
  withdrawOrder = 'WithdrawOrder',
}

type PaymentMethodWithCalculatedAmount = {
  calculatedAmount: number
}

export type SelectedPaymentMethod =
  | SelectedPaymentMethodEwallet
  | SelectedPaymentMethodPoints
  | SelectedPaymentMethodCreditCard
  | SelectedPaymentMethodBankAccount

export type SelectedPaymentMethodEwallet = PaymentMethodEwallet &
  PaymentMethodWithCalculatedAmount

export type SelectedPaymentMethodPoints = PaymentMethodPoints &
  PaymentMethodWithCalculatedAmount & {
    usePoints: number
  }

export type SelectedPaymentMethodCreditCard = PaymentMethodCreditCard &
  PaymentMethodWithCalculatedAmount

export type SelectedPaymentMethodBankAccount = PaymentMethodBankAccount &
  PaymentMethodWithCalculatedAmount

export function isSelectedMethod<
  B extends object,
  S extends PaymentMethodWithCalculatedAmount
>(item: B | S | undefined | null): item is S {
  return item ? 'calculatedAmount' in item : false
}

export function getFlowTypeKey(value: string) {
  const index = Object.values(FlowType).indexOf(value as unknown as FlowType)

  const key = Object.keys(FlowType)[index]

  return key
}

export function getOrderType(value: FlowType): FlowType {
  if (value === FlowType.quickBuy) {
    return FlowType.purchase
  }

  return value
}
