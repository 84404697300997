import { computed, ref } from 'vue'
import { businessNumber } from './../mask'
import { BankAccountSchema } from '../../bank-account'

const initiateValues = {
  type: 'bankAccount',
  abn: '',
  name: '',
  accountName: '',
  bsb: '',
  accountNumber: '',
  abnName: '',
}

const form = ref(initiateValues)

const bankAccountSchema = computed<BankAccountSchema>(() => ({
  fields: [
    {
      key: 'abn',
      type: 'abn',
      label: 'Payee CBN',
      validation: {
        rules: `required|max:${businessNumber.length}|businessNumber`,
      },
      placeholder: businessNumber.placeholder,
      disclaimer: 'Name of CBN holder shown here',
      mask: {
        mask: businessNumber.mask,
        lazy: true,
      },
      unmaskedModelValue: true,
      analyticsLabel: 'Payee ABN',
      mode: 'passive',
    },
    {
      key: 'name',
      type: 'string',
      label: 'Payee name',
      validation: {
        rules: 'required|max:256',
      },
      disabled: !form.value['abnName'],
      labelSuffix: '(editable)',
      analyticsLabel: 'Payee name',
    },
    {
      key: 'accountName',
      type: 'string',
      label: 'Bank account name',
      validation: {
        rules: 'required|min:2|max:256',
      },
      disabled: !form.value['abnName'],
      analyticsLabel: 'Bank account name',
    },
    {
      key: 'bsb',
      type: 'composite',
    },
    {
      key: 'accountNumber',
      type: 'string',
      label: 'Account Number',
      mask: {
        mask: '0000000[00000]',
        lazy: true,
      },
      validation: {
        rules: 'required|numeric|min:7|max:12',
      },
      disabled: !form.value['abnName'],
      analyticsLabel: 'Account number',
      replacer: (value: string) => value.replace(/[^\d]/g, ''),
    },
  ],
  types: {
    bsb: {
      fields: [
        {
          type: 'string',
          key: 'institutionNumber',
          label: 'Institution Number',
          validation: {
            rules: 'required|numeric|length:3',
          },
          disabled: !form.value['abnName'],
          mask: {
            mask: '000',
            lazy: true,
          },
          analyticsLabel: 'institutionNumber',
        },
        {
          type: 'string',
          key: 'transitNumber',
          label: 'Transit Number',
          validation: {
            rules:
              'required|numeric|length:5|canadian_bsb:@institutionNumber,institutionNumber',
          },
          disabled: !form.value['abnName'],
          mask: {
            mask: '00000',
            lazy: true,
          },
          analyticsLabel: 'transitNumber',
        },
      ],
    },
  },
}))

const bankAccountSchemaEditName: BankAccountSchema = {
  fields: [
    {
      type: 'string',
      key: 'abn',
      label: 'Payee CBN',
      disabled: true,
    },
    {
      type: 'string',
      key: 'name',
      label: 'Payee name',
      validation: {
        rules: 'required|max:256',
      },
      labelSuffix: '(editable)',
      analyticsLabel: 'Payee name',
    },
    {
      type: 'string',
      key: 'accountName',
      label: 'Account name',
      disabled: true,
    },
    {
      type: 'string',
      key: 'institutionNumber',
      label: 'Institution number',
      disabled: true,
    },
    {
      type: 'string',
      key: 'transitNumber',
      label: 'Transit number',
      disabled: true,
    },
    {
      type: 'string',
      key: 'accountNumber',
      label: 'Account number',
      disabled: true,
    },
  ],
  types: {},
}

export default {
  initiateValues,
  form,
  bankAccountSchema,
  bankAccountSchemaEditName,
  showFormDescription: false,
}
