import { OtpLocalStorage } from '/~/extensions/otp/composables/core/OtpLocalStorage'
import { useLogger } from '/~/composables/logger'
import { api, Jwt } from '/~/core'

const logger = useLogger('otp')

export type Channel = 'mobile' | 'email'

export type DeliveryChannel = {
  channel: Channel
  name: string
  value: string
  roleAssignUponRegistration: string
}

export type DeliveryChannelOption = {
  icon: string
  label: string
  value: Channel
  token: string
}

export type TriggerPoint = 'login' | 'enrolment'

export interface InitiateOtpResponse {
  data: {
    otpId: string
    expiresAt: string
    processingTimeInSeconds: number
    validityInSeconds: number
  }
}

export type VerifyOtpErrorResponse = {
  data: {
    attemptsRemaining: number
    message: string
    severity: 'error' | 'warning'
  }
}

export abstract class Otp {
  public channel
  public name
  public triggerPoint
  public otpId
  public validityInSeconds
  public processingTimeInSeconds
  public token
  public tokenData
  public timestamp
  public jwt: Jwt
  public isInitiating = false
  public isVerifying = false
  protected storage: Otp.Storage

  get deliveryChannels() {
    return this.jwt.data?.otp?.deliveryChannels ?? []
  }

  constructor(triggerPoint: TriggerPoint) {
    this.triggerPoint = triggerPoint
    this.storage = new OtpLocalStorage(this.triggerPoint)
  }

  public async initiate(channel: Channel) {
    try {
      this.isInitiating = true
      this.channel = channel

      this.setJwt()

      await this.jwt.get()

      const deliveryChannel = this.deliveryChannels.find(
        (m) => m.channel === channel
      )

      if (!deliveryChannel) {
        throw new Error('No valid delivery channels available')
      }

      this.name = deliveryChannel.name

      const otpData = await this.sendInitiateRequest({
        token: await this.jwt.get(),
      })

      if (!otpData) {
        throw new Error('There was an error initiating otp')
      }

      this.setOtpData(otpData)
      this.storage.put('lastOtp', this)

      logger.debug('OTP initiated', this)

      return otpData
    } finally {
      this.isInitiating = false
    }
  }

  protected async sendInitiateRequest(options: any) {
    const response = await api.post(
      '/v3/otp',
      { deliveryChannel: this.channel },
      options
    )

    if (!response?.data) {
      throw new Error('No data returned from OTP endpoint')
    }

    return response.data as InitiateOtpResponse['data']
  }

  abstract setJwt(token?: string): void

  protected setOtpData(otpData: InitiateOtpResponse['data']) {
    const { validityInSeconds, processingTimeInSeconds, otpId } = otpData

    this.otpId = otpId
    this.validityInSeconds = validityInSeconds
    this.processingTimeInSeconds = processingTimeInSeconds
    this.timestamp = +new Date(Date.now() + processingTimeInSeconds * 1000)
  }

  public async verify(payload: { guess: number | string }) {
    this.isVerifying = true

    this.restore()

    try {
      return api.post(`/v3/otp/${this.otpId}`, payload, {
        token: await this.jwt.get(),
      })
    } finally {
      this.isVerifying = false
    }
  }

  public getStorage() {
    return this.storage
  }

  public restore() {
    const lastOtp = this.storage.restore()

    if (!lastOtp) {
      console.warn('No data found for last otp')

      return this
    }

    for (const key in lastOtp) {
      switch (key) {
        case 'jwt':
          this.setJwt(lastOtp[key]?.token)
          break
        case 'deliveryChannels':
        case 'storage':
          continue
        default:
          this[key] = lastOtp[key]
      }
    }

    this.setJwt()
  }
}

export default Otp
