import Bugsnag from '@bugsnag/js'
import Vue, { ref, computed } from 'vue'
import { UserRawData } from '/~/types/api'
import api from '/~/core/api'
import emitter from '/~/core/emitter'
import { Saving } from '/~/core/saving'
import { useLogger } from '/~/composables/logger'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { useProvider } from '/~/composables/provider'
import { EonxUser } from './core/user'

const logger = useLogger('use-user')

type UploadKey = {
  expire: number
}

const USER_META_DATA = 'userMetaData'

interface UserMetaData {
  [key: string]: {
    createdAt?: string
    updatedAt?: string
    user?: {
      externalId: string
    }
    value: boolean | string | Array<string>
    type?: string
  }
}

export enum UserMetaDataKey {
  termsAndConditions = 'terms_and_conditions',
  marketingCommsEmail = 'marketing_comms_email',
  directDebitConfirmed = 'cancel_existing_direct_debits_confirmation',
  directDebitReminder = 'direct-debit-modal-reminder-disabled',
  membershipConfirmation = 'membership-confirmation',
  thirdPartyUsersEngagementAgreement = 'third_party_users_engagement_agreement',
}

const user = ref(new EonxUser(window.eonx?.user))
const uploadKeys = ref<{
  unsecure: UploadKey | null
  secure: UploadKey | null
}>({ unsecure: null, secure: null })
const userClaim = ref({ hasClaim: false, redeemed: true })
const depositDetails = ref<any | null>(null)
const savedCategories = ref<Saving[]>([])

const autopayModal = ref(true)
const fetching = ref(false)
const updating = ref(false)
const userMetaDataLoaded = ref(false)

const userMetaData = ref<UserMetaData>({})

const hasAccessToApp = computed((): boolean => {
  const { isTermsAcceptanceRequired } = useProvider()

  return !isTermsAcceptanceRequired.value || isTermsAccepted.value
})

const unsecureUploadKey = computed(() =>
  (uploadKeys.value.unsecure?.expire ?? 0) > Date.now() / 1000
    ? uploadKeys.value.unsecure
    : null
)
const secureUploadKey = computed(() =>
  (uploadKeys.value.secure?.expire ?? 0) > Date.now() / 1000
    ? uploadKeys.value.secure
    : null
)
const hasUserClaim = computed(
  () => userClaim.value.hasClaim && !userClaim.value.redeemed
)

const isTermsAccepted = computed<boolean>(
  () =>
    Boolean(userMetaData.value[UserMetaDataKey.termsAndConditions]?.value) ??
    false
)
const marketingCommsEmail = computed(
  () => userMetaData.value[UserMetaDataKey.marketingCommsEmail]?.value || null
)
const directDebitConfirmed = computed<boolean>(
  () =>
    metadataValueIsTrue(
      String(userMetaData.value[UserMetaDataKey.directDebitConfirmed]?.value)
    ) ?? true
)
const disabledReminderModalAccountIds = computed<Array<string>>(
  () =>
    (userMetaData.value[UserMetaDataKey.directDebitReminder]
      ?.value as Array<string>) || []
)
const membershipConfirmed = computed<boolean>(() =>
  metadataValueIsTrue(
    String(userMetaData.value[UserMetaDataKey.membershipConfirmation]?.value)
  )
)

function metadataValueIsTrue(value: string) {
  return ['true', 'yes', '1'].includes(value?.toString().toLowerCase())
}

function setUser(newUser: UserRawData | null) {
  user.value = new EonxUser(newUser)

  if (user.value.externalId && (Bugsnag as any)._client) {
    Bugsnag.setUser(user.value.externalId)
  }
}

async function fetchUserDetails() {
  fetching.value = true

  const { data } = await api.get<UserRawData>('/v3/user-details')

  fetching.value = false

  if (data) {
    setUser(data)
    return data
  } else {
    throw new Error('No user')
  }
}

async function updateUserDetails(payload: any) {
  try {
    updating.value = true
    const { data } = await api.put<UserRawData>('/v3/user-details/', payload)

    if (data) {
      setUser(data)
    }

    return data
  } finally {
    updating.value = false
  }
}

async function fetchUserMetadata() {
  try {
    const response = await api.get<UserMetaData>('/v3/user-metadata')

    userMetaData.value = {
      ...getUserMetadataLocal(),
      ...(response?.data ?? {}),
    }
    userMetaDataLoaded.value = true
  } catch (error) {
    logger.error(error)
  }
}

function getUserMetadataLocal() {
  const userMetaDataLocal = localStorage.getItem(USER_META_DATA)

  return JSON.parse(userMetaDataLocal || '{}')
}

function updateUserMetadataLocal(
  key: string,
  value: string | boolean | Array<string>
) {
  const userMetaDataLocal = JSON.parse(
    localStorage.getItem(USER_META_DATA) || '{}'
  )

  userMetaDataLocal[key] = { value }
  localStorage.setItem(USER_META_DATA, JSON.stringify(userMetaDataLocal))
}

function updateUserMetadata(
  key: string,
  value: string | boolean | Array<string>,
  saveLocal?: boolean
) {
  if (saveLocal) {
    updateUserMetadataLocal(key, value)
    userMetaData.value[key] = { value }
    return value
  } else {
    const response = api.put(`/v3/user-metadata/${key}`, {
      metadataValue: value,
    })

    Vue.set(userMetaData.value, key, { value })

    return response
  }
}

async function checkAccessToApp() {
  const { isTermsAcceptanceRequired } = useProvider()

  if (isTermsAcceptanceRequired.value && !isTermsAccepted.value) {
    emitter.emit('modal:show', {
      name: 'terms-conditions',
      props: {
        forceConfirmation: true,
      },
    })
  }
}

function updateWelcomeModal(status: string) {
  return updateUserMetadata('welcome-modal', status)
}

async function updateAutoPayModal(accountId: string) {
  if (
    Array.isArray(
      userMetaData.value[UserMetaDataKey.directDebitReminder]?.value
    )
  ) {
    ;(
      userMetaData.value[UserMetaDataKey.directDebitReminder]
        .value as Array<string>
    ).push(accountId)
  } else {
    userMetaData.value[UserMetaDataKey.directDebitReminder] = {
      value: [accountId],
    }
  }

  const response = await updateUserMetadata(
    UserMetaDataKey.directDebitReminder,
    userMetaData.value[UserMetaDataKey.directDebitReminder].value
  )

  return response
}

function enabledAccountIdForReminderModal(accountId: string) {
  return !disabledReminderModalAccountIds.value?.find?.(
    (item) => item === accountId
  )
}

async function updateDirectDebitsConfirmation(status: string) {
  const response = await updateUserMetadata(
    UserMetaDataKey.directDebitConfirmed,
    status
  )

  userMetaData.value[UserMetaDataKey.directDebitConfirmed] = {
    value: Boolean(response?.data),
  }
  return response
}

function checkAutoPayRemindMeAgain(accountId: string) {
  if (autopayModal.value) {
    updateAutoPayModal(accountId)
  }
}

async function updateTermsAcceptance(status: string) {
  const response = await updateUserMetadata(
    UserMetaDataKey.termsAndConditions,
    status
  )

  userMetaData.value[UserMetaDataKey.termsAndConditions] = {
    value: Boolean(response?.data),
  }
}

async function fetchUploadKey() {
  if (unsecureUploadKey.value) {
    return unsecureUploadKey.value
  }

  try {
    const { data } = await api.get('/me/uploads/signature', {
      baseURL: '/2.0',
    })

    if (data) {
      uploadKeys.value.unsecure = data
    }
  } catch (error) {
    Vue.notify({
      text: 'Cannot get upload key from server',
      type: 'error',
      duration: 5000,
    })
  }

  return unsecureUploadKey.value
}

async function fetchUploadSecureKey() {
  try {
    const { data } = await api.get('/me/uploads/signature/secure', {
      baseURL: '/2.0',
    })

    if (data) {
      uploadKeys.value.secure = data
    }
  } catch (error) {
    Vue.notify({
      text: 'Cannot get upload secure key from server',
      type: 'error',
      duration: 5000,
    })
  }

  return secureUploadKey.value
}

async function fetchUserClaimStatus() {
  const { data } = await api.get('/claim', { baseURL: '/1.1' })

  if (data) {
    userClaim.value = data
  }
}

function updateUserPassword(payload: any) {
  return api.patch('/user-details/password', payload)
}

async function fetchDepositDetails() {
  depositDetails.value = null

  const { data } = await api.get('/v3/ewallets/deposit-details')

  depositDetails.value = data
}

function applyCouponCode({
  ewalletReference,
  coupon,
}: {
  ewalletReference: string
  coupon: string
}) {
  const { fetchPaymentMethods } = usePaymentMethods()

  return api
    .post(`/v3/ewallets/${ewalletReference}/redeem-coupon`, { coupon })
    .then((response) => {
      fetchPaymentMethods()

      Vue.notify({
        text: 'Redemption code successfully activated',
        type: 'success',
        duration: 3000,
      })
      return response.data
    })
    .catch((error) => {
      console.error(error)
      throw error
    })
}

async function fetchSavedCategories() {
  try {
    const { data } = await api.get('/v3/activity/savings/categories')

    if (Array.isArray(data)) {
      const processData = data.map((cat) => new Saving(cat))

      savedCategories.value = processData
    }
  } catch (error) {
    console.error(error)
  }
}

async function confirmUserMembership(status: boolean) {
  const response = await updateUserMetadata(
    UserMetaDataKey.membershipConfirmation,
    status
  )

  return response?.data?.data ?? {}
}

export function useUser() {
  return {
    user,
    unsecureUploadKey,
    secureUploadKey,
    hasUserClaim,
    depositDetails,
    savedCategories,
    membershipConfirmed,
    directDebitConfirmed,
    autopayModal,
    marketingCommsEmail,
    fetching,
    updating,
    userMetaData,
    userMetaDataLoaded,

    setUser,
    hasAccessToApp,
    checkAccessToApp,
    applyCouponCode,
    confirmUserMembership,
    checkAutoPayRemindMeAgain,
    enabledAccountIdForReminderModal,

    fetchUploadKey,
    fetchUploadSecureKey,
    fetchUserClaimStatus,
    fetchDepositDetails,
    fetchSavedCategories,
    fetchUserDetails,
    fetchUserMetadata,
    updateUserDetails,
    updateTermsAcceptance,
    updateWelcomeModal,
    updateAutoPayModal,
    updateUserPassword,
    updateDirectDebitsConfirmation,
    updateUserMetadata,
  }
}
