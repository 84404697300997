import { JwtStrategy } from '/~/core/Jwt/JwtStrategy'
import { OtpLocalStorage } from '/~/extensions/otp/composables/core/OtpLocalStorage'
import { api, Options } from '/~/core'

export class DefaultJwtStrategy extends JwtStrategy implements IJwtStrategy {
  promise?: null | Promise<any>

  async generateToken(options: Options = {}) {
    const otpStorage = new OtpLocalStorage('login')
    const otp = otpStorage.restore() ?? {}

    if (!this.promise) {
      if (otp.otpId) {
        options['headers'] = {
          ...options['headers'],
          'Otp-Id': otp.otpId,
        }
      }

      this.promise = api.get<string>('/jwt', options)
    }

    const response = await this.promise

    // if session cookie is expired, clear storage
    if (response?.status === 401) {
      this.storage.clear()
      eonx.jwtExpired = true // router is not yet initialized, so we can't utilise the emitter
    }

    this.promise = null

    return response?.data?.token as string
  }
}
