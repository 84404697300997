import Storage from '/~/utils/localStorage'

export class JwtLocalStorage implements Jwt.Storage {
  protected key = 'jwt'

  put(token?: string) {
    if (token) {
      Storage.set(this.key, JSON.stringify({ token }))
    } else {
      Storage.remove(this.key)
    }
  }

  restore() {
    return Storage.get<Jwt.Storage>(this.key)?.token ?? ''
  }

  clear() {
    Storage.remove(this.key)
  }
}
