import { ConfigType } from 'dayjs/esm'
import { PaymentMethod } from '/~/types/api'
import { createDate } from '/~/utils/format'
import {
  PAYMENT_METHODS_SORT_ORDER,
  PaymentMethodType,
} from '/~/composables/payment-methods'

export function filterByType(
  paymentMethods: PaymentMethod[],
  type: PaymentMethodType
) {
  return paymentMethods.filter((i) => i?.type === type)
}

export function getExpiryDate(method: PaymentMethod, paymentDate: ConfigType) {
  if (!method || method.type !== PaymentMethodType.creditCard) {
    return {
      date: null,
      isExpired: false,
      isExpiryLessThanScheduledAt: false,
    }
  }

  const date = createDate(
    new Date(method.expiry.year, method.expiry.month, 0)
  ).subtract(2, 'day')
  /*
    NOTE: we are not using `month - 1` to jump to the next month and then substract 1 day (0 parameter) to land on a last day of the selected month
  */

  const isExpired = date.isSameOrBefore(createDate())
  const isExpiryLessThanScheduledAt = paymentDate
    ? date.isSameOrBefore(paymentDate)
    : false

  return {
    date,
    isExpired,
    isExpiryLessThanScheduledAt,
  }
}

export function getBrandImage(
  brand: 'American Express' | 'Visa' | 'Mastercard'
) {
  const images = {
    'American Express': '/images/payment-methods/amex.svg',
    Visa: '/images/payment-methods/visa.svg',
    Mastercard: '/images/payment-methods/mastercard.svg',
  }

  return images[brand]
}

export function sortPaymentMethodsByType(methods: PaymentMethod[]) {
  return methods.sort(
    (a, b) =>
      PAYMENT_METHODS_SORT_ORDER[a.type] - PAYMENT_METHODS_SORT_ORDER[b.type]
  )
}
