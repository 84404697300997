import Vue, { VNode } from 'vue'
import { BaseAnalyticsTool } from '/~/core/analytics/base-analytics'

function mapEventKey(eventKey: string | undefined): string | undefined {
  switch (eventKey) {
    case ' ':
      return 'space'
    case 'Escape':
      return 'esc'
    case 'ArrowUp':
      return 'up'
    case 'ArrowDown':
      return 'down'
    case 'ArrowLeft':
      return 'left'
    case 'ArrowRight':
      return 'right'
    default:
      return eventKey?.toLowerCase()
  }
}

interface IBinding {
  value: any
  arg: string
  modifiers: {
    custom: boolean
    debounce: boolean
    space: boolean
    esc: boolean
    up: boolean
    down: boolean
    left: boolean
    right: boolean
    [key: string]: boolean
  }
}

export function initAnalyticsDirective(Analytics: BaseAnalyticsTool) {
  function createEventHandler(binding: IBinding) {
    if (!binding.arg) {
      console.warn('Event argument is missing in v-analytics directive.')
      return
    }

    const debounce = binding.arg === 'input' || !!binding.modifiers.debounce
    const validKeyEvents = ['keydown', 'keyup', 'keypress']

    return (event: KeyboardEvent) => {
      const eventToSend =
        typeof binding.value === 'function'
          ? binding.value(event)
          : binding.value

      if (!eventToSend) return

      // For keyboard events, check if the key is existing in the modifiers and mapped
      if (validKeyEvents.includes(binding.arg)) {
        const eventKeyMapped = mapEventKey(event?.key)

        if (eventKeyMapped && binding.modifiers[eventKeyMapped]) {
          Analytics.sendEvent({ ...eventToSend }, { debounce })
        } else {
          const message = `Key "${event?.key}" is not mapped or not supported.`

          console.debug(message)
        }
      } else {
        // For non-keyboard events, send the event directly
        Analytics.sendEvent({ ...eventToSend }, { debounce })
      }
    }
  }

  Vue.directive('analytics', {
    bind(el: HTMLElement, binding: IBinding, vnode: VNode) {
      const eventHandler = createEventHandler(binding)

      if (binding.arg === 'mount') {
        Analytics.sendEvent(binding.value, { debounce: false })
      } else if (
        binding.modifiers.custom &&
        vnode.componentInstance // Check if the 'custom' modifier is present
      ) {
        // If vnode.componentInstance exists, it means this is a Vue component
        // If listeners are defined on component proceed adding eventHandler
        vnode.componentInstance?.$on(binding.arg, eventHandler)
      } else {
        // Otherwise, treat it as a normal DOM element
        el.addEventListener(binding.arg, eventHandler)
      }
    },
  })
}
